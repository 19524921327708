.style-selector {
    display: flex;
    scroll-behavior: smooth;
    justify-content: start;
    width: calc(100% - 40px); 
    overflow-x: auto;
}

.style-button {
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
    flex: 0 0 auto;
    transition: transform 1s ease;
}



.style-image {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    border: 5px solid white;
    cursor: pointer;
}

.style-image.selected {
    border-color: #5b41d8;
}


.style-label {
    text-align: center;
    color: black
}


.aspect-ratio-selector {
    border-radius: 10px;
    width: 100%;

}

.aspect-ratio-button {
    background: transparent;
    border: 2px solid #555;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 20px;
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease;
    white-space: nowrap;
}

.aspect-ratio-button.selected {
    border-color: #5b41d8;
    color : #5b41d8;
}




.ratios {
    display: flex;
    flex-wrap: wrap;
}

.styles-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}


.scroll-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    font-size: 28px;
}

.scroll-button.left {
    margin-right: 10px;
}

.scroll-button.right {
    margin-left: 10px;
}